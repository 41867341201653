<template>
<div>
  <v-btn v-if="$store.state.auth.user._id != offer.creator && $store.state.auth.user.isActive && !offer.fictif && canSendProposal && !hasSentProposal && $store.getters['auth/accountReady']" 
        class="" color="green" dark elevation="0" block rounded
        @click="showDialogProposal = true">
    <v-icon small color="" class="pr-1">mdi-basket</v-icon> Faire une offre
  </v-btn>
  <v-btn v-if="$store.state.auth.user._id != offer.creator && canSendProposal && hasSentProposal && !offer.fictif && $store.state.auth.user.isActive" 
          @click="showDialogProposal = true" 
          elevation="0" color="teal" block rounded class="" dark>
          <v-icon small color="" class="pr-1">mdi-plus-circle</v-icon>  Nouvelle proposition
  </v-btn>
  <v-btn v-if="!$store.state.auth.isLogin" to="/register"
          elevation="0" color="orange" small outlined class="" dark>
          <v-icon x-small color="" class="pr-1">mdi-plus-circle</v-icon>  Créer mon compte
  </v-btn>

  
  <!-- DIALOG PROPOSITION -->
  <v-dialog v-model="showDialogProposal" transition="dialog-top-transition"
            max-width="640" :fullscreen="$vuetify.breakpoint.width < 600"
            v-if="offer != null && !offer.fictif" style="z-index:2000"
            content-class="dialog-large-bordered">
      <v-card elevation="0" style="border-radius:0;">

        <v-toolbar color="" elevation="1" dense>
          <v-icon small color="green" class="pr-1">mdi-basket</v-icon> 
          <b class="green--text">Faire une offre</b>
          <v-spacer></v-spacer>
          <v-btn class="mr-0" small icon text elevation="0" @click="showDialogProposal = false">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-col cols="12" class="pt-0">
          <v-card-title>
            <small>Le prix de cette annonce est de</small> 
            <span class="pl-2 d-inline-block font-led primary--text" style="font-size:20px;">
              {{ offer.amountMony }} {{ $store.state.app.monyUnity }}
            </span>
          </v-card-title>


          <v-row class="mx-0">
            <v-col cols="6" md="6" class="px-0">
              <WalletCardSM :wallet="$store.state.auth.user.walletMain"></WalletCardSM>
            </v-col>
            <v-col cols="6" md="6" class="px-1" v-for="wallet in $store.state.auth.user.walletsDeposit" :key="wallet._id">
              <WalletCardSM :wallet="wallet"></WalletCardSM>
            </v-col>
          </v-row>


          <v-card-title class="pb-0">
            <v-icon small color="" class="pr-1">mdi-chevron-down</v-icon> Faire une offre
          </v-card-title>

          <v-card-text color="teal">
            Vous pouvez proposer au vendeur un montant inférieur ou suppérieur au prix annoncé.
            Le vendeur pourra accepter ou refuser votre proposition, en fonction des autres offres qu'il recevra.
          </v-card-text>

          <!-- <v-text-field v-model.number="amountProposal" type="number"
                        :color="amountCorrect ? 'green' : 'red'" class="mb-4 textarea-amountMony"
                        @keydown="keydownAmount" :prefix="monyToEuro()"
                        :label="'Montant de l\'offre en ' + $store.state.app.monyUnity"
                        hide-details outlined :suffix="$store.state.app.monyUnity" rounded>
          </v-text-field> -->

          <v-row class="mb-0 mt-0 mx-0">
            <v-col cols="12" class="px-1 pb-0 mx-auto">
              <div class="px-4 py-2 pb-1 chip-amount  teal--text" style="border-color:#009688;">
                <span class="font-led">{{ lblAmountProposal }} <small>PRT</small></span>
                <small class="lbl-euro white--text" v-if="lblAmountProposal!='...'">
                  ~{{ (parseFloat(lblAmountProposal)*100).toFixed(2) }}€
                </small>
              </div>
            </v-col>
          </v-row>

          <v-row class="mb-4 mx-0">
            <v-col cols="12" class="pa-0 px-1">
              <v-alert :color="amountCorrect ? 'green' : 'red'" dark outlined dense class="mb-2">
                <template v-if="amountCorrect">Montant maximum autorisé : </template>
                <template v-if="!amountCorrect">Montant maximum autorisé : </template>
                <b>{{ maxAmount.toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
              </v-alert>
            </v-col>

            <template v-for="i in [1,2,3,4,5,6,7,8,9]">
              <v-col cols="4" :key="i" class="pa-1" @click="addNumToAmount(i)">
                <v-btn block outlined color="teal" class="btn-keyboard">{{ i }}</v-btn>
              </v-col>
            </template>

            <v-col cols="4" class="pa-1" @click="addNumToAmount(0)">
              <v-btn block outlined color="teal" class="btn-keyboard">0</v-btn>
            </v-col>
            <v-col cols="4" class="pa-1">
              <v-btn block outlined color="white" :disabled="lblAmountProposal == ''" @click="addNumToAmount('.')" class="btn-keyboard">.</v-btn>
            </v-col>
            <v-col cols="4" class="pa-1">
              <v-btn block outlined color="white" @click="removeNumToAmount()" class="btn-keyboard">
                <v-icon>mdi-backspace-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-textarea v-model="message" color="green" rows="3" maxLength="500" hide-details
                      outlined label="Laisser un message au vendeur..." class="mb-4">
          </v-textarea>

          <v-btn block color="green darken-1" large :disabled="!amountCorrect" elevation="0" :dark="amountCorrect" @click="sendProposition()">
            <v-icon small color="" class="pr-1">mdi-handshake</v-icon>
            Envoyer mon offre
          </v-btn>


        </v-col>
      </v-card>
  </v-dialog>
</div>
</template>

<style >
  .btn-keyboard{
    height:36px !important;
    font-size: 20px !important;
  }
</style>

<script>

import axios from "axios"
import core from '../../plugins/core.js'
import WalletCardSM from '@/components/wallet/WalletCardSM.vue'

const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'home',
  props: ['offer', 'inDialog'],
  components: {
    WalletCardSM
  },
  data: () => ({
    showDialogProposal: false,
    amountProposal: "",
    message: "",
  }),
  mounted: async function(){
    this.amountProposal = this.offer.amountMony.toString()
  },
  methods: {
    async sendProposition(){
      let params = { offerId: this.offer._id,
                     amount: this.amountProposal,
                     msgTxt: this.message
                   }
      let { data } = await axios.post('/market/send-proposition', params)
      if(data.error === true){
        this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'red', 
                                  title: "Une erreur est survenue.",
                                  text: data.msg })
      }else{
        this.showDialogProposal = false
        this.$store.dispatch('auth/refreshUserData')
        this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Proposition envoyée.",
                                  text: "Votre proposition a bien été envoyée au vendeur." })

      }
    },

    keydownAmount(event){
      if(event.key.length > 1 || this.amountProposal == null) return

      let amountProposalStr = this.amountProposal + ""

      //transforme le "0" en chaine vide, sauf si on rajoute un point
      if(amountProposalStr == "0" && event.key != ".") this.amountProposal = ""
      
      //si le montant tapé fait déjà 4 caractère 
      if(amountProposalStr.length >= 4 && amountProposalStr.indexOf(".") == -1) 
        event.preventDefault()

      if(!this.amountCorrect) event.preventDefault()

      console.log("this.amountProposal", typeof amountProposalStr, amountProposalStr)
      //si le nombre de chiffre apres la virgule est déjà de 4
      if(amountProposalStr.length >= 4 
      && amountProposalStr.indexOf(".") > 0
      && amountProposalStr.length >= parseFloat(this.amountProposal).toFixed(4).length) 
        event.preventDefault()

      let ascii = event.key.charCodeAt(0)
      if((ascii < 48 || ascii > 57) //si ce n'est pas un nombre
      && (event.key != "." || amountProposalStr.indexOf(".") > -1) //et que ce n'est pas un point, ni un 2eme point
      ) {
          event.preventDefault() //annule la frappe au clavier
      }
    },
    convertUnityMony(amountUnity){
      return core.convertUnityMony(amountUnity, this.$store.state.auth.monyConvertValue)
    },
    convertMonyUnity(amountMony){
      return core.convertMonyUnity(amountMony, this.$store.state.auth.monyConvertValue)
    },
    monyToEuro(){ 
      if(this.amountProposal == "") return
        return "~" + (parseFloat(this.amountProposal) * config.monyToEuro).toFixed(2) + "€" 
    },

    //keyboard
    addNumToAmount(num){
      console.log("addNumToAmount", num, this.amountProposal) //, this.amountProposal.indexOf(num))
      if(num == "." && this.amountProposal.indexOf(num) >= 1) return
      if(num == "." && this.amountProposal.length == 0) return
      this.amountProposal = this.amountProposal + "" + num
    },
    removeNumToAmount(){
      this.amountProposal = this.amountProposal.substr(0, this.amountProposal.length-1)
    },
  },
  computed: {
    canSendProposal(){
      if(!this.$store.state.auth.isLogin) return false

      let canSend = true
      let props = this.$store.state.auth.user.propositionsSent
      props.forEach((prop) => {
        if(prop.offer == this.offer._id){
          if(props.nego[props.nego.length - 1].status != "REFUSED") canSend = false
        }
      })
      return canSend && (this.$store.state.auth.user._id != this.offer.creator._id)
    },
    hasSentProposal(){
      let sent = false
      let props = this.$store.state.auth.user.propositionsSent
      props.forEach((prop) => {
        if(prop.offer._id == this.offer._id) sent = true
      })
      return sent
    },
    errorAmount(){
      let amountMax = this.offer.amountMony * 1.5
      let errorAmount = 'Proposition max autorisée : ' + amountMax
      //console.log("errorAmount", this.amountProposal, '>', amountMax, (this.amountProposal > amountMax))
      if(this.amountProposal > amountMax) return errorAmount
      else return false
    },
    amountCorrect(){
      return (this.amountProposal <= this.maxAmount)
    },
    lblAmountProposal(){
      let amount = this.amountProposal
      if(amount == ""
      || amount == ".") amount = "..."
      
      return amount
    },
    maxAmount(){
      let mainW = this.convertUnityMony(this.$store.state.auth.user.walletMain.amountUnity)
      let depositW = this.$store.state.auth.user.walletsDeposit[0].amountMony
      return (mainW + depositW) // ? mainW : depositW
    }
  },
  watch: {
  }, 
}
</script>
